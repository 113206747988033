<template>
    <div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">
      <div v-if="!viLoader">
        <div class="row ml-2 mr-2">				
            <div class="col-lg-12">
                <div>
                    
                    <div class=" tbllist">
                      <div class="restable">
                        <div class="resrow resheader">
                          <!-- <div class="cell">S.No.</div> -->
                          <div class="cell">Payment ID</div>
                          <div class="cell">Fee Type</div>
                          <div class="cell">Paid Amount</div>
                          <div class="cell">Status</div>
                          <div class="cell">Transaction Date</div>
                          <div class="cell">Transaction By</div>
                           <div class="cell" style="text-align: center;">Invoice Print</div>
                         
                        </div>
                        <div class="resrow" v-for="(data, index) in paymentHistoryList" :key="index" >
                          <!-- <div class="cell" data-title="S.No.">{{ index + 1 }}.</div> -->
                          <div class="cell" data-title="orderId" v-if="data.razorPayResponse && data.razorPayResponse.razorpay_payment_id">
                            <a href="javascript:void(0);" @click.prevent="getPaymentDetails(data.razorPayResponse.razorpay_payment_id,index)" >{{ data.razorPayResponse.razorpay_payment_id  }} </a>
                          </div>
                          <div v-else class="cell" data-title="Candidate Name">
                            Paid By School Admin
                          </div>
                          <div class="cell" data-title="Candidate Name">
                            {{ data.type }}
                          </div>
                          <div class="cell" data-title="Email">
                            {{ data.grandTotalAmount }}
                          </div>
                           <div class="cell" data-title="status" 
                            :style="data.status == 'Success' ? 'color : lightgreen;' : 'color : darkorange'"
                           >
                            {{ data.status }}
                          </div>
                          <div class="cell" data-title="Parent Name">
                            {{ data.createdDate | dateFormat }}
                          </div>
                          <div class="cell" data-title="Email">
                            {{ data.paymentBy }}
                          </div>
                           <div class="cell" data-title="Email" style="text-align: center;">
                             <button class="btn round orange tooltipt mpopup"  v-if="data.status == 'Success'" @click.prevent="printPreview(data._id)"><i class="fas fa-print"></i><span class="tooltiptext">View Invoice/Print</span></button>
                           
                          
                          </div>
                          
                        </div>
                      </div>
                    </div>
                </div>
            </div>				
        </div>
      </div>
      <div>
        <b-modal
          id="viewPayMentDetails"
          style="width: 100%"
          no-close-on-backdrop
          no-close-on-esc
        >
        <button type="button" class="close"  @click.prevent="closePoup">×</button>

          <div class="contact-form">
            <h2>Payment Details</h2>
            <br />
            <div>
              <form
                data-vv-scope="crtfeeTypeValidate"
                id="crtfeeplans"
                accept-charset="utf-8"
              >
                <div class="form-row">
                  <div class="form-group input-group col-md-4 mt-3 pr-md-3" >
                    <span class="has-float-label">
                      <input
                        disabled
                        type="text"
                        v-model="paymentDetails.amount"
                        name="feeTypeId"
                        class="form-control form-input"
                        id="fpnameid"
                        placeholder="Fees Type id"
                      />
                     
                      <label  for="fpnameid"
                        >Amount <span class="required">*</span></label
                      >
                    </span>
                  </div>
                   <div class="form-group input-group col-md-4 mt-3 pr-md-3" >
                    <span class="has-float-label">
                      <input
                        disabled
                        type="text"
                        v-model="paymentDetails.contact"
                        name="feeTypeId"
                        class="form-control form-input"
                        id="fpnameid"
                        placeholder="Fees Type id"
                      />
                     
                      <label  for="fpnameid"
                        >Phone Number <span class="required">*</span></label
                      >
                    </span>
                  </div>
                   <div class="form-group input-group col-md-4 mt-3 pr-md-3" >
                    <span class="has-float-label">
                      <input
                        disabled
                        type="text"
                        v-model="paymentDetails.description"
                        name="feeTypeId"
                        class="form-control form-input"
                        id="fpnameid"
                        placeholder="Fees Type id"
                      />
                     
                      <label  for="fpnameid"
                        >Type <span class="required">*</span></label
                      >
                    </span>
                  </div>
                   <div class="form-group input-group col-md-4 mt-3 pr-md-3" >
                    <span class="has-float-label">
                      <input
                        disabled
                        type="text"
                        v-model="paymentDetails.email"
                        name="feeTypeId"
                        class="form-control form-input"
                        id="fpnameid"
                        placeholder="Fees Type id"
                      />
                     
                      <label  for="fpnameid"
                        >Email<span class="required">*</span></label
                      >
                    </span>
                  </div>
                   <div class="form-group input-group col-md-4 mt-3 pr-md-3" >
                    <span class="has-float-label">
                      <input
                        disabled
                        type="text"
                        v-model="paymentDetails.status"
                        name="feeTypeId"
                        class="form-control form-input"
                        id="fpnameid"
                        placeholder="Fees Type id"
                      />
                     
                      <label  for="fpnameid"
                        >Status <span class="required">*</span></label
                      >
                    </span>
                  </div>
                   <div class="form-group input-group col-md-4 mt-3 pr-md-3" >
                    <span class="has-float-label">
                      <input
                        disabled
                        type="text"
                        v-model="paymentDetails.order_id"
                        name="feeTypeId"
                        class="form-control form-input"
                        id="fpnameid"
                        placeholder="Fees Type id"
                      />
                     
                      <label  for="fpnameid"
                        >Payment Order ID <span class="required">*</span></label
                      >
                    </span>
                  </div>
                   <div class="form-group input-group col-md-4 mt-3 pr-md-3" >
                    <span class="has-float-label">
                      <input
                        disabled
                        type="text"
                        v-model="paymentDetails.method"
                        name="feeTypeId"
                        class="form-control form-input"
                        id="fpnameid"
                        placeholder="Fees Type id"
                      />
                     
                      <label  for="fpnameid"
                        >Transaction method <span class="required">*</span></label
                      >
                    </span>
                  </div>
                   
                   <div class="form-group input-group col-md-4 mt-3 pr-md-3" v-if="paymentDetails.error_reason">
                    <span class="has-float-label">
                      <input
                        disabled
                        type="text"
                        v-model="paymentDetails.error_reason"
                        name="feeTypeId"
                        class="form-control form-input"
                        id="fpnameid"
                        placeholder="Fees Type id"
                      />
                     
                      <label  for="fpnameid"
                        >Reason<span class="required">*</span></label
                      >
                    </span>
                  </div>
                   <div class="form-group input-group col-md-4 mt-3 pr-md-3" v-if="paymentDetails.error_source" >
                    <span class="has-float-label">
                      <input
                        disabled
                        type="text"
                        v-model="paymentDetails.error_source"
                        name="feeTypeId"
                        class="form-control form-input"
                        id="fpnameid"
                        placeholder="Fees Type id"
                      />
                     
                      <label  for="fpnameid"
                        >Failed Source <span class="required">*</span></label
                      >
                    </span>
                  </div>
                  <div class="form-group input-group col-md-4 mt-3 pr-md-3" v-if="paymentDetails.error_description">
                    <span class="has-float-label">
                      <textarea
                        disabled
                        v-model="paymentDetails.error_description"
                        name="feeTypeId"
                        style="color: red;"
                        class="form-control form-input"
                        id="fpnameid"
                        placeholder="Fees Type id"
                      ></textarea>
                     
                      <label  for="fpnameid"
                        >Description<span class="required">*</span></label
                      >
                    </span>
                  </div>
                  
                </div>
              </form>
    
              <div class=" mt-3 pt-3" style="border-top: 1px solid #e5e5e5">
               
                <div class="text-center dk_iconsml">
                 
                  <button
                    @click.prevent="closePoup"
                    type="button"
                    class="btn btncl clsmdl"
                    id="clbtn"
                  >
                       <span>Cancel</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </b-modal>
         <b-modal
          id="viewPayMentDetailsPreview"
          no-close-on-backdrop
          no-close-on-esc
        >
        <button type="button" class="close"  @click.prevent="closePreviewConfirmPopUp">×</button>

          <div class="contact-form">
            <h2>Preview option</h2>
            <br />
            <div>
              
                <div class="form-row">
                  <div
                          class="form-group input-group col-md-6 mt-3"
                          style="border-bottom: 1px solid #e5e5e5"
                        >
                          <span style="color: #6c757d" class="mr-3">
                            Preview with Header
                            <span class="required">*</span></span
                          >
                          <label class="switch">
                            <input
                              type="checkbox"
                              id="togBtn"
                              v-model="withHeader"
                            />
                            <div class="slider"></div>
                          </label>
                   </div>
                  
                   <div
                          class="form-group input-group col-md-6 mt-3"
                          style="border-bottom: 1px solid #e5e5e5"
                        >
                          <span style="color: #6c757d" class="mr-3">
                            Preview with footer
                            <span class="required">*</span></span
                          >
                          <label class="switch">
                            <input
                              type="checkbox"
                              id="togBtn"
                              v-model="withFooter"
                            />
                            <div class="slider"></div>
                          </label>
                   </div>
                  
                </div>
              
    
              <div class=" mt-3 pt-3" style="border-top: 1px solid #e5e5e5">
               
                <div class="text-center dk_iconsml">
                 <button
                          type="button"
                          class="btn btnsml"
                          id="svbtn"
                          @click.prevent="previewConfirmPopUp"
                        >
                            <span>Preview</span>
                        </button>
                  <button
                    @click.prevent="closePreviewConfirmPopUp"
                    type="button"
                    class="btn btncl clsmdl"
                    id="clbtn"
                  >
                       <span>Cancel</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </b-modal>
        <!-- no-close-on-backdrop no-close-on-esc -->
        <b-modal id="printSection" >
          <div style="margin-bottom: 5%">
            <!-- <div class="feedate">{{ new Date() | dateFormat }}</div> -->
            <div class="feedate" >
              <button
                  type="button"
                  class="btn gry_bg tooltipt mpopup ml-3"
                  id="clbtn"
                  @click.prevent="printDetails"
                >
                  <i class="fas fa-print"></i> <span class="tooltiptext">Print</span>
                </button>
                <button
                  type="button"
                  class="btn gry_bg tooltipt mpopup ml-3"
                  id="clbtn"
                  @click.prevent="closePreviewPopUp"
                >
                  <i class="fas fa-times-circle"></i> <span class="tooltiptext">Close</span>
                </button>
              </div>
            <div id="invoice" style="width: 733px;">
              <table cellpadding="0" cellspacing="0"> 
                <tr v-if="withHeader">
                  <td style="background: #2954a2;padding:0.1em">
                    <table border="0" >
                      <tr>
                        <td
                          style="
                            width: 20%;
                            text-align: center;
                            vertical-align: middle;
                            background: #2954a2;padding:0.1em;
                          "
                        >
                          <!--<div
                            style="
                              background: #fff;
                              border-radius: 50%;
                              width: 50%;
                              margin: 10px auto;
                              padding: 6px 0;
                            "
                          >-->
                           <img style="width:80%" v-if="schoolDetails.image && schoolDetails.image.path"
                            :src="schoolDetails.image.path"
                            :alt="schoolDetails.accountName"
                            :title="schoolDetails.accountName"
                          />
                          <img v-else
                            :src="schoolDetails.image"
                            :alt="schoolDetails.accountName"
                            :title="schoolDetails.accountName"
                          />
    
                         <!-- </div>-->
                        </td>
                        <td
                          style="
                            width: 80%;
                            text-align: center;
                            vertical-align: middle;
                            background: #2954a2;padding:0.1em;
                          "
                        >
                          <h1
                            style="
                              font-size: 1.9rem;
                              font-weight: 600;
                              margin: 0;
                              padding: 3px 0;
                              color: #fff;
                            "
                          >
                            {{ schoolDetails.accountName }}
                          </h1>
                          <p
                            style="
                              font-size: 1rem;
                              margin: 0;
                              padding: 6px 0;
                              color: #fff;
                            "
                          >{{ schoolDetails.address }}, {{ schoolDetails.street }}, {{ schoolDetails.district ? schoolDetails.district : '' }}, {{ schoolDetails.state ? schoolDetails.state : '' }}, {{ schoolDetails.pincode }}, {{ schoolDetails.country }}
                            
                          </p>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style="padding:0.1em">
                    <div
                      style="
                        font-size: 1.2rem;
                        font-weight: bold;
                        text-align: center;
                        padding: 8px;
                      "
                    >
                      FEE RECEIPT
                    </div>
                    <table style="font-size: 0.94rem">
                      <tr>
                        <td
                          style="
                            padding: 4px;
                            font-weight: 600;
                            width: 15%;
                            text-align: left;
                            vertical-align: middle;
                          "
                        >{{ historyDetails.source == 'Online' ? 'Transaction ID' : 'Transaction BY' }}
                          
                        </td>
                        <td
                          style="
                            padding: 4px;
                            width: 25%;
                            text-align: left;
                            vertical-align: middle;
                          "
                        >
                        {{ historyDetails.source == 'Online' ? historyDetails.razorPayResponse.razorpay_payment_id : 'School Admin'}}
                          
                        </td>
                        <td
                          style="
                            padding: 4px;
                            font-weight: 600;
                            width: 15%;
                            text-align: left;
                            vertical-align: middle;
                          "
                        >
                          Transaction Date
                        </td>
                        <td
                          style="
                            padding: 4px;
                            width: 25%;
                            text-align: left;
                            vertical-align: middle;
                          "
                        >
                          {{ historyDetails.createdDate | dateFormat }}
                        </td>
                       
                      </tr>
                      <tr>
                        <td
                          style="
                            padding: 4px;
                            font-weight: 600;
                            width: 15%;
                            text-align: left;
                            vertical-align: middle;
                          "
                        >
                          Student Name
                        </td>
                        <td
                          style="
                            padding: 4px;
                            width: 15%;
                            text-align: left;
                            vertical-align: middle;
                          "
                        >
                          {{ studentsDetails.firstName + " " + studentsDetails.lastName }}
                        </td>
                        <td
                          style="
                            padding: 4px;
                            font-weight: 600;
                            width: 15%;
                            text-align: left;
                            vertical-align: middle;
                          "
                        >
                          Roll No.
                        </td>
                        <td
                          style="
                            padding: 4px;
                            width: 15%;
                            text-align: left;
                            vertical-align: middle;
                          "
                        >
                          {{ studentsDetails.rollNoAllocated || '' }}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style="
                            padding: 4px;
                            font-weight: 600;
                            width: 15%;
                            text-align: left;
                            vertical-align: middle;
                          "
                        >
                          Parent / Guardian Name
                        </td>
                        <td
                          style="
                            padding: 4px;
                            width: 15%;
                            text-align: left;
                            vertical-align: middle;
                          "
                        >
                          {{ studentsDetails.parent ? (studentsDetails.parent.firstName + " " + studentsDetails.parent.lastName) : '' }}
                        </td>
                        <td
                          style="
                            padding: 4px;
                            font-weight: 600;
                            width: 15%;
                            text-align: left;
                            vertical-align: middle;
                          "
                        >
                          Invoice No.
                        </td>
                        <td
                          style="
                            padding: 4px;
                            width: 15%;
                            text-align: left;
                            vertical-align: middle;
                          "
                        >
                          {{ historyDetails.invoiceId  || 'INV-001' }}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style="
                            padding: 4px;
                            font-weight: 600;
                            width: 15%;
                            text-align: left;
                            vertical-align: middle;
                          "
                        >
                          Academic Year
                        </td>
                        <td
                          style="
                            padding: 4px;
                            width: 15%;
                            text-align: left;
                            vertical-align: middle;
                          "
                        >
                          {{ studentsDetails.academicYear ? (studentsDetails.academicYear.from + " - " + studentsDetails.academicYear.to) : '' }}
                        </td>
                        <td
                          style="
                            padding: 4px;
                            font-weight: 600;
                            width: 15%;
                            text-align: left;
                            vertical-align: middle;
                          "
                        >
                        
                          Class & Section
                        </td>
                        <td
                          style="
                            padding: 4px;
                            width: 15%;
                            text-align: left;
                            vertical-align: middle;
                          "
                        >
                          {{ studentsDetails.class ? (studentsDetails.class.className + " (" + studentsDetails.section.name + ")" ) : '' }}
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td v-if="historyDetails.type == 'Term-Fees'" style="padding:0.1em">
                    <table class="admintbl pb-3 table birthday_table text-center" >
                          <thead>
                              <tr>
                                  <!-- <th>S.No.</th> -->
                                  <th>Term Name</th>
                                  <th>Fees Type</th>
                                  <th>Refundable</th>
                                  <th>Fee Amount ($)</th>
                                  <th>Total Amount ($)</th>
                              </tr> 
                          </thead>
                          <tbody v-for="(data, index) in Object.keys(summaryInDetail)" :key="index">
                            <tr class="trmname">
                              <td colspan="4" data-label="Term Name" align="left"><b>{{ data }}</b>&nbsp;</td>
                              <td style="text-align:right"><b>{{ totalFeesTerm(data) | currencyFormat }}</b>&nbsp;</td>
                            </tr>
                            <tr v-for="(ter, issndex) in summaryInDetail[data]"
                            :key="issndex">
                              <td align="left"></td>
                              <td data-label="Fees Type">{{ ter.feeTypeId ? ter.feeTypeId.name : '' }}&nbsp;</td>
                            
                              <td data-label="Refundable"> {{ ter.isRefundable ? "yes" : "No" }}&nbsp;</td>
                              <td data-label="Fee Amount ($)">{{ ter.amount | currencyFormat }}&nbsp;</td>
                              <td data-label="Total Amount ($)" class="tblnone">&nbsp;</td>
                            </tr>
                          </tbody>
                       </table>
                       <table border="0" class="table birthday_table text-center">
                         <tr class="tot">
                           <td colspan="4" style="text-align:right;font-weight:600"><b>Total Amount :</b></td>
                           <td style="text-align:right;font-weight:600"><b>{{ historyDetails.totalSumAmount | currencyFormat}}</b>&nbsp;</td>
                         </tr>
                         <tr class="tot">
                           <td colspan="4" style="text-align:right;font-weight:600"><b>Discount Amount :</b></td>
                           <td style="text-align:right;font-weight:600"><b>{{ historyDetails.getDiscountAmount | currencyFormat}}</b>&nbsp;</td>
                         </tr>
                         <tr class="tot">
                           <td colspan="4" style="text-align:right;font-weight:600"><b>Sibling Discount :</b></td>
                           <td style="text-align:right;font-weight:600"><b>{{ historyDetails.adminOverrideDiscount | currencyFormat}}</b>&nbsp;</td>
                         </tr>
                          <tr class="tot">
                           <td colspan="4" style="text-align:right;font-weight:600"><b> Amount Paid :</b></td>
                           <td style="text-align:right;font-weight:600"><b>Rs: {{ historyDetails.grandTotalAmount | currencyFormat}}</b>&nbsp;</td>
                         </tr>
                       </table>
                       
                       <span style="margin-left: 90px;">{{ convertAmountToWords(historyDetails.grandTotalAmount) }}</span>
    
                  </td>
                  <td v-else style="padding:0.1em">
                    <table border="1" class="table birthday_table text-center">
                    <thead>
                      <tr>
                        <td scope="col" rowspan="6" style="font-weight: 600;">
                          Fee Name
                        </td>
                        <td scope="col" rowspan="6" style="font-weight: 600;">
                          Amount
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in adhocFeeList.feeLineItem"
                        :key="index"
                      >
                        <td data-label="Fee Name"> {{ item.name }}
                        
                        </td>
                        <td data-label="Fee Name">{{ item.netAmount }}
                         
                        </td>
                      </tr>
                      <tr style="background:#e7e7e7;color: #444444;">
                        <td style="color:#2954A2"><b>Total Amount</b></td>
                        <td class="txtrgt">
                          <b> Rs: {{ getTotalNetAmount() }}</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <span style="margin-left: 90px;">{{ convertAmountToWords(getTotalNetAmount()) }}</span>
                  </td>
                </tr>
                <tr v-if="historyDetails.type == 'Term-Fees'">
                  <td style="padding:0.1em">
                    <table style="font-size: 0.94rem">
                      <tr>
                        <td style="width: 15%; text-align: left; padding: 4px">
                          Late Fee
                        </td>
                        <td v-if="historyDetails.getPenaltyAmountOnly && historyDetails.getPenaltyAmountOnly > 0"
                          style="
                            width: 10%;
                            text-align: center;
                            padding: 4px;
                            background: #e1e1e1;
                            border: 1px solid #000;
                          "
                        >
                          {{ historyDetails.planDetails.penalty ? historyDetails.planDetails.penalty.mode == "Rs($)" ? "Rs." + " " + historyDetails.planDetails.penalty.penaltyAmountValue : historyDetails.planDetails.penalty.penaltyPercentageValue + "%" : "0%" }}
                        </td>
                        <td v-else style="
                            width: 10%;
                            text-align: center;
                            padding: 4px;
                            background: #e1e1e1;
                            border: 1px solid #000;
                          ">
                          NA
                        </td>
                        <td style="width: 25%; text-align: left; padding: 4px">
                          Payment Type
                        </td>
                        <td
                          style="
                            width: 10%;
                            text-align: center;
                            padding: 4px;
                            background: #e1e1e1;
                            border: 1px solid #000;
                          "
                        >
                          {{ historyDetails.source }}
                        </td>
                        <td style="width: 15%; text-align: left; padding: 4px" v-if="historyDetails.source == 'Online'">
                          Order ID
                        </td>
                        <td style="width: 25%; text-align: left; padding: 4px" v-else>
                          Cheque Number/DD No.
                        </td>
                        <td v-if="historyDetails.source == 'Online'"
                          style="
                            width: 15%;
                            text-align: center;
                            padding: 4px;
                            background: #e1e1e1;
                            border: 1px solid #000;
                            overflow-wrap: break-word;
                          "
                        >
                          {{ historyDetails.razorPayResponse.razorpay_order_id }}
                        </td>
                        <td v-else
                          style="
                            width: 15%;
                            text-align: center;
                            padding: 4px;
                            background: #e1e1e1;
                            border: 1px solid #000;
                          "
                        >
                          {{ historyDetails.paymentType == 'Demand Draft' ? historyDetails.demandDraft : historyDetails.paymentType == 'Cheque' ? historyDetails.chequeNo : historyDetails.amount  }}
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: left; padding: 4px">Discount</td>
                        <td v-if="historyDetails.getDiscountAmount && historyDetails.getDiscountAmount > 0"
                          style="
                            text-align: center;
                            padding: 4px;
                            background: #e1e1e1;
                            border: 1px solid #000;
                          "
                        >
                        {{ historyDetails.planDetails.discount ? historyDetails.planDetails.discount.mode == "Rs($)" ? "Rs." + " " + historyDetails.planDetails.discount.discountAmountValue
                            : historyDetails.planDetails.discount.discountPercentageValue + "%" : "0%"
                         }}
                          
                        </td>
                        <td style="text-align: center; padding: 4px" v-else>
                         NA
                        </td>
                        <td style="text-align: left; padding: 4px">
                          Sibling Discount
                        </td>
                        <td
                          style="
                            text-align: center;
                            padding: 4px;
                            background: #e1e1e1;
                            border: 1px solid #000;
                          "
                        >
                        {{ historyDetails.adminOverrideDiscount && historyDetails.sibiling ? historyDetails.sibiling.siblingDiscount : 'NA'}} <span v-if="historyDetails.adminOverrideDiscount && historyDetails.sibiling">  %</span>
                        </td>
                        <td style="text-align: left; padding: 4px">&nbsp;</td>
                        <td style="text-align: center; padding: 4px">&nbsp;</td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style="padding: 10px">
                    <div class="row">
                  <div class="col-md-6">
                    <div
                      style="
                        padding: 10px;
                        float: right;
                        text-align: center;
                        width: 200px;
                        clear: both;
                      "
                    >
                    <img
                    v-if="schoolSettingDetails.schoolSeal && schoolSettingDetails.schoolSeal.path"
                        :src="schoolSettingDetails.schoolSeal.path"
                        alt="Avatar"
                        width="80"
                        height="80"
                      />
                      Seal
                    </div>
                    <div
                      style="
                        float: right;
                        width: 100%;
                        text-align: right;
                        margin-right: 16px;
                        padding: 8px;
                      "
                    >
                    School Official Seal
                    </div>
                    </div>
                    <div class="col-md-6">
                      <div
                      style="
                        padding: 10px;
                        float: right;
                        text-align: center;
                        width: 200px;
                        clear: both;
                      "
                    >
                    <img
                        v-if="
                          schoolSettingDetails.signature && schoolSettingDetails.signature.path
                        "
                        :src="schoolSettingDetails.signature.path"
                        alt="Avatar"
                        width="80"
                        height="80"
                      />
                      Signature
                    </div>
                    <div
                      style="
                        float: right;
                        width: 100%;
                        text-align: right;
                        margin-right: 16px;
                        padding: 8px;
                      "
                    >
                      Cashier / Manager/Admin
                    </div>
                    </div>
                  </div>
                    <!-- <img
                        v-if="
                          schoolSettingDetails.signature && schoolSettingDetails.signature.path
                        "
                        :src="schoolSettingDetails.signature.path"
                        alt="Avatar"
                        width="80"
                        height="80"
                      />-->
                      <!-- <label style="text-align: center"
                        >Signature of principal<br />with official seal
                      </label> -->
                      <!-- <img
                        v-if="
                          schoolSettingDetails.schoolSeal && schoolSettingDetails.schoolSeal.path
                        "
                        :src="schoolSettingDetails.schoolSeal.path"
                        alt="Avatar"
                        width="80"
                        height="80"
                      />  -->
                      <!-- <div
                     
                    >
                      Cashier / Manager/Admin
                    </div> -->
                   
                    
                  </td>
                </tr>
                <tr v-if="withFooter">
                  <td style="padding: 8px; background: #e7e7e7">
                    <div
                      style="
                        font-weight: 600;
                        font-size: 0.9rem;
                        padding-bottom: 6px;
                      "
                    >
                      Terms & Conditions
                    </div>
                    <div style="font-size: 0.75rem">
                      {{ schoolSettingDetails.termsAndConditionDescription || ''}}
                      
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </b-modal>
    </div>
         <vi-spinner
                    v-if="viLoader"
                    text="Loading..."
                    textColor="vi-brand-color"
                    class="flex-fill h-100 vi-fs12"
                    style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
                  />
      </div>
    </template>
    <script>
    
    
    import ViService from "@/services/ViService";
    import secureUI from "../../utils/secureUI";
    import ViSpinner from "../Common/ViSpinner.vue";
    
    export default {
      name: "vi-admission-home",
      
      data() {
        return {
          viLoader: false,
          paymentHistoryList: [],
          paymentDetails: {},
          historyDetails: {},
          studentsDetails: {},
          schoolDetails: {},
          schoolSettingDetails: {},
          summary: [],
          summaryInDetail: {},
          adhocFeeList: {},
          withHeader: false,
          withFooter: false,
          isParent: false
        };
      },
      created() {
          this.getPaymentHistoryList()
      },
      computed: {
          styles(){
           var brwHeight = window.innerHeight;
           return{
             height: (brwHeight - 90) + 'px'
           };
          },
          userProfile(){
            let userData = secureUI.sessionGet("user");
            return userData.userDetails.profile.name
          }
         // userData.userDetails.profile.name == "Parent"
      },
      methods: {
        previousRoute(){
         let data = localStorage.getItem('previousTab');
         localStorage.setItem("activeTab",data);
         localStorage.setItem("previousTab",this.$route.path);
          this.$router.push({ path: data });
        },
        redirectRoute(vipath) {
          if(this.$route.path !== vipath) {
            this.$router.push({ path: vipath })
          };
        },
         getTotalNetAmount() {
          if (this.adhocFeeList.feeLineItem && this.adhocFeeList.feeLineItem.length > 0) {
            let totalAmount = 0;
    
            for (let x of this.adhocFeeList.feeLineItem) {
              if (x.netAmount) {
                totalAmount = totalAmount + Number(x.netAmount);
              }
            }
            return totalAmount;
          } else {
            return 0;
          }
        },
        totalFeesTerm(value) {
          if (this.summaryInDetail[value]) {
            return this.summaryInDetail[value]
              .map((o) => o.amount)
              .reduce((a, c) => {
                return a + c;
              });
          } else {
            return 0;
          }
        },
       convertAmountToWords(amount) {
      const oneToNineteen = [
        "", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine",
        "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"
      ];
    
      const tens = [
        "", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"
      ];
    
      const scales = ["", "Thousand", "Lakh", "Crore"];
    
      function convertLessThanOneThousand(number) {
        if (number === 0) {
          return "";
        } else if (number < 20) {
          return oneToNineteen[number] + " ";
        } else if (number < 100) {
          return tens[Math.floor(number / 10)] + " " + convertLessThanOneThousand(number % 10);
        } else {
          return oneToNineteen[Math.floor(number / 100)] + " Hundred " + convertLessThanOneThousand(number % 100);
        }
      }
    
      function convert(number) {
        if (number === 0) {
          return "Zero Rupees";
        } else {
          let result = "";
          let scaleIndex = 0;
    
          while (number > 0) {
            const chunk = number % 1000;
            if (chunk !== 0) {
              result = convertLessThanOneThousand(chunk) + scales[scaleIndex] + " " + result;
            }
            number = Math.floor(number / 1000);
            scaleIndex++;
          }
    
          return "(Indian Rupees " + result.trim() + " Only)";
        }
      }
    
      return convert(amount);
    },
    
    // // Example usage:
    // const amountInWords = convertAmountToWords(99);
    
      async getPaymentDetails(paymentId,index){
         let userData = secureUI.sessionGet("user");
    
          if (!userData) {
            this.$toasted.error("Please login and do the action");
          } else {
    
         
         let userData = secureUI.sessionGet("user");
         const response = await ViService.viXGet(`/payment/getPaymentDetails?paymentId=${paymentId}&studentId=${this.$route.params.id}`,userData.token);
          
          if (response.isSuccess) {
              this.paymentDetails = response.data
              this.paymentDetails.amount = this.paymentDetails.amount/100
    
               const historyDetails = this.paymentHistoryList[index]
              
               if(historyDetails && this.paymentDetails){
    
                 if( historyDetails.status == 'Failed' && this.paymentDetails.status == 'captured'){
    
                 const response = await ViService.viXPut(`/contact/updatePaymentHistory/${historyDetails._id}`,
                  {
                    status: 'Success',
                  },
                  userData.token
                  );
    
                  if (response.isSuccess) {
                    this.getPaymentHistoryList()
                  }
                  
                 }
               }
              
              this.$bvModal.show("viewPayMentDetails");
          } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                    localStorage.removeItem('user');
                    this.$router.push('/login');
             }
          }
          }
    
      },
      closePoup(){
         this.paymentDetails = {}
         this.$bvModal.hide("viewPayMentDetails");
      },
      closePreviewConfirmPopUp(){
        this.withHeader = false,
        this.withFooter = false,
        this.paymentDetails = {}
        this.$bvModal.hide("viewPayMentDetailsPreview");
      },
      previewConfirmPopUp(){
        this.$bvModal.show("printSection");
      },
      closePreviewPopUp (){
         this.paymentDetails = {}
         this.$bvModal.hide("printSection");
      },
      async printPreview(id) {
       
       
        
        if(id){
    
           let userData = secureUI.sessionGet("user");
           
          if (!userData) {
            this.$toasted.error("Please login and do the action");
          } else {
            const response = await ViService.viXGet(`/contact/getPaymentInvoiceDetails?historyId=${id}`,userData.token);
     
            if (response.isSuccess) {
                const data = secureUI.secureGet(response.data);
                
                this.historyDetails  = data.historyDetails || {},
                this.studentsDetails = data.students || {},
                this.schoolSettingDetails = data.schoolSettingDetails || {}
                this.schoolDetails = data.schoolDetails || {}
                
                if(this.historyDetails.type == 'Term-Fees'){
                   this.summary = data.summary || [],
                   this.summaryInDetail = data.summaryInDetail || {}
                   this.adhocFeeList = {}
                }
    
                if(this.historyDetails.type == 'Adhoc-Fees'){
                   this.adhocFeeList = data.adhocSummeryDetails || {},
                   this.summary =  [],
                   this.summaryInDetail = {}
                }
            } else {
              this.$toasted.error(response.message);
               if(response.message == 'Your session has expired, please login'){
                    localStorage.removeItem('user');
                    this.$router.push('/login');
             }
            }
            
            if(userData.userDetails.profile.name == "Parent"){
              this.$bvModal.show("printSection");
              this.withHeader = true,
              this.withFooter = true
             // this.$router.push("/parent/search/home");
            }else{
              this.$bvModal.show("viewPayMentDetailsPreview");
    
            }
    
          }
         
        }
        
      },
        async printDetails() {
        
          let section = document.getElementById("print");
    
          if (!section) {
            section = document.createElement("div");
            
    
            section.id = "print";
            document.body.appendChild(section);
          }
    
          section.innerHTML = "";
          window.print();
        },
        async getPaymentHistoryList() {
          let userData = secureUI.sessionGet("user");
    
          if (!userData) {
            this.$toasted.error("Please login and do the action");
          } else {
             this.viLoader = true
            const response = await ViService.viXGet(`/contact/getPaymentHistory?studentId=${this.$route.params.id}`,userData.token);
     
            if (response.isSuccess) {
                const data = secureUI.secureGet(response.data);
                    this.paymentHistoryList = data
            } else {
              this.$toasted.error(response.message);
               if(response.message == 'Your session has expired, please login'){
                    localStorage.removeItem('user');
                    this.$router.push('/login');
             }
            }
             this.viLoader = false
          }
      }
      },
      components: {
          ViSpinner
      }
      
    };
    </script>
    
    <style scoped>
    ul,
    ul li,
    p,
    div,
    ol {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    
    #invoice {
      margin: 0 auto;
      width: 999px;
    
      clear: both;
      position: relative;
      background: #fff;
    }
    .feedate {
      font-size: 0.78rem;
      text-align: right;
      margin: 0 auto;
      padding: 10px 0;
      width: 999px;
    }
    table,
    td,
    th {
      border: 1px solid black;
    }
    @media print {
       .noprint {
          visibility: hidden;
       }
    }
    </style>